// Import styles
import( /* webpackChunkName: "styles" */ '../css/index.css');

// Import images
// import( /* webpackChunkName: "logo" */ '../img/logo.svg');

import "core-js/stable";

// Import scripts
import( /* webpackChunkName: "lazysizes" */ 'lazysizes');
import( /* webpackChunkName: "lazysizes-bgset" */ 'lazysizes/plugins/bgset/ls.bgset' );
import( /* webpackChunkName: "lazysizes-parentfit" */ 'lazysizes/plugins/parent-fit/ls.parent-fit' );
import( /* webpackChunkName: "lazysizes-objectfit" */ 'lazysizes/plugins/object-fit/ls.object-fit' );

if ( !window.HTMLPictureElement || document.msElementsFromPoint ) {
  import( /* webpackChunkName: "lazyszies-polyfill-ie" */ 'lazysizes/plugins/respimg/ls.respimg' );
}

const site = () => import(/* webpackChunkName: "site-bundle" */ './site.js');

// App main
site().then( ( { default: siteApp } ) => {
  window.siteApp = siteApp;
  window.siteApp.init();
});
